<template>
  <div>
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >商家充值报表，统计各渠道商家充值情况；适用于运营人员和财务人员了解商家充值情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img
                src="./Report/img/close.png"
                @click="closeShow = false"
                alt=""
              />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper bgFFF">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
            <el-select
              v-model.trim="formInline.operationId"
              filterable
              size="15"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.storeName"
                :value="value.storeId"
                :key="value.storeId"
                v-for="value in storeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Date_search')" label-width="80px">
            <a-date-picker
              ref="datePicker"
              :YearShowHidden="selectkeys[3]"
              :selectkeys="selectkeys"
              :previousDate="previousDate"
              :nextDate="nextDate"
            ></a-date-picker>
          </el-form-item>
          <div class="but">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                getData();
                page = 1;
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <!-- <el-button type="primary" icon="el-icon-delete">清空</el-button> -->
            <!-- 导出按钮加权限v-if=$route.meta.authority.button.export -->
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="exportFile"
              :loading="loading"
              >导出
            </el-button>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <!--列表区域-->
        <div class="tableWrapper paddingB20 graphShadow">
          <el-table
            :data="tableData"
            :header-cell-style="rowClass"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="statDate" :label="$t('searchModule.date')" width="150">
            </el-table-column>
            <el-table-column prop="storeName" :label="$t('searchModule.Merchant')" width="150">
            </el-table-column>
            <el-table-column label="充值">
              <el-table-column prop="wxRecharge" label="微信充值" width="120">
              </el-table-column>
              <el-table-column
                prop="zfbRecharge"
                label="支付宝充值"
                width="120"
              >
              </el-table-column>
              <el-table-column
                prop="totalRecharge"
                label="充值小计"
                width="120"
              >
              </el-table-column>
            </el-table-column>
            <el-table-column label="退款">
              <el-table-column prop="wxRefund" label="微信退款" width="120">
              </el-table-column>
              <el-table-column prop="zfbRefund" label="支付宝退款" width="120">
              </el-table-column>
              <el-table-column prop="totalRefund" label="退款小计" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column label="合计">
              <el-table-column prop="wxTotal" label="微信合计" width="120">
              </el-table-column>
              <el-table-column prop="zfbTotal" label="支付宝合计" width="120">
              </el-table-column>
              <el-table-column prop="total" label="合计" width="120">
              </el-table-column>
            </el-table-column>
            <!-- <el-table-column label="">
              <el-table-column prop="total" label="合计" width="120">
              </el-table-column>
            </el-table-column> -->
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper" v-if="total > 0">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              background
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { exportExcelNew } from "../../common/js/public.js";
export default {
  name: "merchantRechargeReport",
  data() {
    return {
      closeShow: true,
      formInline: {
        dateType: "month",
        operationId: "",
      },
      loading: false,
      total: 0,
      page: 1,
      pageSize: 15,
      storeList: [],
      selectkeys: ["date", "month", "week", "year"],
      previousDate: new Date(),
      nextDate: new Date(),
      tableData: [],
    };
  },
  methods: {
    // 自定义表头样式
    rowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        if (columnIndex === 0) {
          return { "text-align": "center" };
        }
      }
      if (rowIndex == 0) {
        if (columnIndex === 1) {
          return {
            "text-align": "center",
          };
        }
      }
      if (rowIndex == 0) {
        if (columnIndex === 2) {
          return { background: "white", "text-align": "center" };
        }
      }
      if (rowIndex == 0) {
        if (columnIndex === 3 || columnIndex === 4) {
          return { background: "white", "text-align": "center" };
        }
      } else {
        return { "text-align": "center" };
      }
    },
    getData() {
      if (!this.formInline.dateType) {
        this.$alert("日期类型不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.formInline.startDate = this.$refs.datePicker
        ? this.$refs.datePicker.getStartDate()
        : "";
      this.formInline.endDate = this.$refs.datePicker
        ? this.$refs.datePicker.getEndDate()
        : "";
      let data = {
        storeId: this.formInline.operationId,
        startDate: this.formInline.startDate,
        endDate: this.formInline.endDate,
        reportType: this.$refs.datePicker
          ? this.$refs.datePicker.getDateType()
          : "",
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      console.log(data, "data---------");
      this.$axios
        .get("/acb/2.0/storeRechargeRefund/list", { data: data })
        .then((res) => {
          if (res.value) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
            console.log(this.tableData, "----------------");
          }
        });
    },
    exportFile() {
      this.formInline.startDate = this.$refs.datePicker
        ? this.$refs.datePicker.getStartDate()
        : "";
      this.formInline.endDate = this.$refs.datePicker
        ? this.$refs.datePicker.getEndDate()
        : "";
      let data = {
        storeId: this.formInline.operationId,
        startDate: this.formInline.startDate,
        endDate: this.formInline.endDate,
        reportType: this.$refs.datePicker
          ? this.$refs.datePicker.getDateType()
          : "",
      };
      let url = "/acb/2.0/storeRechargeRefund/list/export";
      console.log(data, "data---------");
      exportExcelNew(url, data);
    },
    /* 商家名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/store/listStores").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.storeList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
  },
  created() {
    this.getRoleList();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.parkCarReport {
  background: #ffffff;
}

.breadcrumb {
  height: 35px;
}

.searchWrapper {
  padding: 0 12px 0 22px;
}

.demo-form-inline {
  overflow: hidden;
  padding: 22px 0 0 0;
  margin-bottom: 20px;

  .but {
    float: right;
  }
}

.table_header {
  padding: 30px 60px;
  border: 1px solid #ddd;
}

.grid-content {
  text-align: center;
  line-height: 30px;
}

.countTable {
  padding-top: 60px;
  height: 350px;
}

.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

>>>.el-tabs__nav {
  background-color: #fff;
}

>>>.el-tabs__header {
  margin: 0;
}

.info {
  margin: 0 -20px 0 -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);

  .info_content {
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.tableWrapper {
  position: relative;

  .switch {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: 0;
    cursor: pointer;

    img {
      height: 54px;
      width: 40px;
    }
  }
}

.tableWrapper >>> .headerCallStyle {
  height: 54px;
  font-size: 14px;
  background: #F3F7FF !important;
  padding: 5px;
  border-right: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.tableWrapper >>> .el-table {
  width: 100% !important;
}

/deep/.el-table__header {
  width: 100% !important;
}

/deep/.el-table__body {
  width: 100% !important;
}
</style>
